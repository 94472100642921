@import "../css/frontend/animate.css";

@import "../css/frontend/style.css";

@import "../css/frontend/default.css";

.help {
  padding-bottom: 2px;
  border-bottom: 2px dotted;
}
.text-danger {
  font-weight: bold;
  font-size: 16px;
}

.nocaps {
  text-transform: none;
}
.nodecoration {
  text-decoration: none;
}